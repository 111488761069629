
// const localEndpoint = "http://localhost:8080/";
// const localEndpoint = "http://192.168.0.161:8080/";

const localEndpoint = "https://www.zakscoin.skiiishow.com/api/";
export const miningEndpoint = "https://www.zakscoin.skiiishow.com/api/";
// export const miningEndpoint = "http://localhost:8080/";
// export const miningEndpoint = "https://www.zakscoin.skiiishow.com/api/mining/";


export const apiURL = process.env.NODE_ENV === 'production' ? localEndpoint : localEndpoint
