import React from 'react';
import { Modal, Backdrop, Fade, Typography, Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const slideIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const glow = keyframes`
  0% {
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.8);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 215, 0, 1);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.8);
  }
`;

const floating = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const AnimatedDiv = styled('div')(({ open }) => ({
  animation: `${open ? slideIn : ''} 0.5s ease-out`,
}));

const Card = styled(Box)(({ rank }) => ({
  background: rank === 1 ? 'rgba(255, 215, 0, 0.15)'
    : rank === 2 ? 'rgba(192, 192, 192, 0.15)'
    : rank === 3 ? 'rgba(205, 127, 50, 0.15)'
    : 'rgba(255, 255, 255, 0.1)',
  borderRadius: '15px',
  padding: '10px',
  marginBottom: '15px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  boxShadow: rank <= 3 ? '0px 5px 20px rgba(255, 215, 0, 0.5)' : '0px 5px 20px rgba(0, 0, 0, 0.15)',
  animation: rank <= 3 ? `${glow} 3s infinite` : '',
  '@media (max-width: 600px)': {
    padding: '8px',
  },
}));

const Badge = styled(Box)(({ rank }) => ({
  background: rank === 1 ? 'linear-gradient(45deg, #FFD700, #FF6F61)'
    : rank === 2 ? 'linear-gradient(45deg, #C0C0C0, #DE6262)'
    : 'linear-gradient(45deg, #CD7F32, #DE6262)',
  color: '#fff',
  padding: '6px 12px',
  borderRadius: '8px',
  fontWeight: 'bold',
  fontSize: '0.9rem',
  animation: `${pulse} 1.5s infinite`,
  boxShadow: rank <= 3 ? '0 0 10px rgba(255, 255, 255, 0.7)' : 'none',
  '@media (max-width: 600px)': {
    padding: '5px 10px',
    fontSize: '0.8rem',
  },
}));

const Avatar = styled(Box)(({ rank }) => ({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  background: rank === 1 ? 'linear-gradient(135deg, #FFD700, #FF6F61)'
    : rank === 2 ? 'linear-gradient(135deg, #C0C0C0, #DE6262)'
    : rank === 3 ? 'linear-gradient(135deg, #CD7F32, #DE6262)'
    : '#6200ea',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  marginRight: '10px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  '@media (max-width: 600px)': {
    width: '35px',
    height: '35px',
    fontSize: '1rem',
    marginRight: '8px',
  },
}));

const RankIcon = styled(Box)(({ rank }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: rank === 1 ? '#FFD700'
    : rank === 2 ? '#C0C0C0'
    : rank === 3 ? '#CD7F32'
    : '#888',
  fontSize: '2rem',
  marginRight: '10px',
  animation: `${floating} 3s infinite`,
  '@media (max-width: 600px)': {
    fontSize: '1.8rem',
  },
}));

const rankEmojis = (rank) => {
  switch (rank) {
    case 1: return '🥇';
    case 2: return '🥈';
    case 3: return '🥉';
    default: return rank <= 10 ? '🎖️' : '🏅';
  }
};

const getInitials = (name) => {
  const initials = name
    .split(' ')
    .map((word) => word[0])
    .join('')
    .toUpperCase();
  return initials.slice(0, 2);
};

const formatNumber = (num) => {
  if (num >= 1000000) return (num / 1000000).toFixed(1) + 'M';
  if (num >= 1000) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return num;
};



const LeaderboardModal = ({ open, handleClose, leaderboardList = {} }) => {
  const { data = [] } = leaderboardList;

  return (
    <Modal
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
      style: { backdropFilter: 'blur(10px)' },
    }}
  >
      <Fade in={open}>
        <AnimatedDiv
          open={open}
          style={{
            background: 'linear-gradient(135deg, #110830 0%, #4A00E0 100%)',
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.25)',
            padding: '20px',
            borderRadius: '20px',
            maxWidth: '100vw',
            maxHeight: '100vh',
            margin: 'auto',
            marginBottom: '4vh',
            overflowY: 'auto',
            position: 'relative',
            '@media (max-width: 600px)': {
              maxWidth: '95%',
              // padding: '15px',
              maxHeight: '99vh'
            },
          }}
        >
          <Typography
            variant="h4"
            fontFamily="Poppins, sans-serif"
            fontWeight={700}
            textAlign="center"
            color="#FFF"
            sx={{ marginBottom: '20px' }}
          >
         
            🏆
            Leaderboard
          </Typography>

          <Typography
            variant="body2"
            fontFamily="Poppins, sans-serif"
            textAlign="center"
            color="#BBB"
            sx={{ marginBottom: '20px' }}
          >
            Earn points and instantly convert them into dollars 🤑 
          </Typography>

          {data.map((referral, index) => (
            <Card key={index} rank={index + 1}>
              <RankIcon rank={index + 1}>
                {rankEmojis(index + 1)}
              </RankIcon>
              <Avatar rank={index + 1}>
                {getInitials(referral.user.username)}
              </Avatar>
              <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                <Typography
                  variant="h6"
                  fontFamily="Poppins, sans-serif"
                  fontWeight={600}
                  color="#FFF"
                  gutterBottom
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {referral.user.username}
                </Typography>
                <Typography
                  variant="body2"
                  fontFamily="Poppins, sans-serif"
                  color="#DDD"
                  sx={{ marginBottom: '10px' }}
                >
                  ID: {referral.user.telegram_id}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Typography
                    variant="body2"
                    fontFamily="Poppins, sans-serif"
                    color="#FFD700"
                  >
                    Earned: $*****
                    {/* Earned: ${pointsToDollars(referral.user.points)} */}
                  </Typography>
                  <Badge rank={index + 1}>
                    {formatNumber(referral.user.points)} 🟡 
                  </Badge>
                </Box>
              </Box>
            </Card>
          ))}

          {data.length === 0 && (
            <Card>
              <Typography
                variant="h6"
                fontFamily="Poppins, sans-serif"
                fontWeight={600}
                color="#777"
                gutterBottom
                textAlign="center"
              >
                Ranking is empty :(
              </Typography>
            </Card>
          )}

          <CloseIcon
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              cursor: 'pointer',
              color: '#FFF',
              '&:hover': {
                color: '#FF6F61',
                transform: 'scale(1.1)',
              },
            }}
          />
        </AnimatedDiv>
      </Fade>
    </Modal>
  );
};

export default LeaderboardModal;
