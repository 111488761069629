import TurboIcon from '@mui/icons-material/FlashOn';
import UpgradeIcon from '@mui/icons-material/Build';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import BoltIcon from '@mui/icons-material/Bolt';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';

// Define boost data with icons, details, and cost
export const boostData = [
    {
        name: 'Turbo',
        details: 'Boost your speed by 3x for 30 minutes.',
        icon: <TurboIcon sx={{ color: '#FFC107', fontSize: '2rem' }} />,
        cost: 0,   // Free for new users
        level: '3 level',
        freeForNewUsers: false,
    },
    {
        name: 'Recharge',
        details: 'Recharge your energy to full instantly.',
        icon: <UpgradeIcon sx={{ color: '#FFC107', fontSize: '2rem' }} />,
        cost: 50000,
        level: '2 level',
        freeForNewUsers: false,
    },

    {
        name: 'Boost +6',
        details: 'Fully upgrade your tap bot for maximum efficiency.',
        icon: <TouchAppIcon sx={{ color: '#FF5722', fontSize: '2rem' }} />,
        cost: 5000,
        level: '6 level',
        freeForNewUsers: false,
    },
    {
        name: 'Boost +9',
        details: 'Fully upgrade your tap bot for maximum efficiency.',
        icon: <TouchAppIcon sx={{ color: '#FF5722', fontSize: '2rem' }} />,
        cost: 7000,
        level: '9 level',
        freeForNewUsers: false,
    },
    {
        name: 'Super Recharge  And Energy',
        details: 'Supercharge your bot for ultimate efficiency.',
        icon: <BatteryChargingFullIcon sx={{ color: '#FF5722', fontSize: '2rem' }} />,
        cost: 15000,
        level: 'Max level',
        freeForNewUsers: false,
    }
];
// Simulate user balance and whether they are eligible for free boost (could come from props or state)
export const user = {
    balance: 50000,  // User's current ZaksCoin balance
    isNew: true,     // Whether the user is new and eligible for free boosts
};



