import React, { useState } from 'react';
import { Modal, Box, Button, Typography, Card, Grid, LinearProgress, Backdrop, Slide } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import axios from 'axios';
import { boostData, user } from './BoostData';
import { apiURL } from '../api';


const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

// Styled Components
const ModalContainer = styled(Box)(({ theme }) => ({
    background: 'linear-gradient(135deg, #110830 0%, #4A00E0 100%)',
    //   borderRadius: '25px',
    padding: '30px',
    width: '100%',
    maxWidth: '500px',
    maxHeight: '99vh', // Limiting the height to 90% of the viewport height
    margin: 'auto',
    overflowY: 'auto',
    boxSizing: 'border-box',
    animation: `${fadeIn} 0.7s ease-in-out`,
    position: 'relative',
}));

const HeaderSection = styled(Box)(({ theme }) => ({
    marginBottom: '30px',
    padding: '20px',
    borderRadius: '20px',
    background: 'rgba(255, 255, 255, 0.1)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
    textAlign: 'center',
}));

const LargeNumber = styled(Typography)(({ theme }) => ({
    fontSize: '3.5rem',
    color: '#FFC107',
    fontWeight: 900,
    textShadow: '2px 2px 8px rgba(0,0,0,0.7)',
    '@media (max-width: 600px)': {
        fontSize: '2.5rem',
    },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: '1.4rem',
    marginBottom: '10px',
    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
    background: 'rgba(0, 0, 0, 0.2)',
    padding: '10px',
    borderRadius: '15px',
}));

const SectionContainer = styled(Box)(({ theme }) => ({
    padding: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '20px',
    marginBottom: '25px',
}));

const CardStyled = styled(Card)(({ theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    color: '#FFF',
    borderRadius: '20px',
    padding: '15px',
    marginBottom: '15px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    border: '2px solid transparent', // Starting state
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 12px 25px rgba(0, 0, 0, 0.5)',
        border: '2px solid #FFC107', // Glow effect on hover
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#FFC107',
    color: '#270049',
    padding: '12px 25px',
    borderRadius: '25px',
    fontWeight: '700',
    boxShadow: '0 4px 12px rgba(255, 193, 7, 0.6)',
    transition: 'all 0.3s ease',
    '&:hover': {
        backgroundColor: '#FFD54F',
        transform: 'scale(1.05)', // Scale up on hover
        boxShadow: '0 8px 16px rgba(255, 193, 7, 0.8)',
    },
}));


const DetailsContainer = styled(Box)(({ theme }) => ({
    background: '#FFF',
    padding: '20px',
    borderRadius: '15px 15px 0 0',
    position: 'fixed',
    maxHeight: '50vh',
    bottom: 0,
    left: 0,
    right: 0,
    height: '45%',
    boxShadow: '0 -5px 15px rgba(0, 0, 0, 0.3)',
    animation: `${fadeIn} 0.5s ease-in-out`,
    zIndex: 2000, // Ensure it appears above the modal
}));

// Main Modal Component
const BoostModal = ({ open, handleClose, userData, telegram_id, fetchUserData = f => f }) => {

    const [selectedBoost, setSelectedBoost] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    
    const handleBoostClick = (boost) => {
        if (
            (boost.name === 'Super Recharge' && userData.energy >= 500) ||
            (boost.name === 'Boost x9' && userData.perClick === 9) ||
            (boost.name === 'Boost x6' && userData.perClick === 6)
        ) {
            setErrorMessage(`You already have ${boost.name} active!`);
            return;
        }

        setSelectedBoost(boost);
        setErrorMessage('');
    };


    const applyBoost = () => {
        if (!selectedBoost) return;

        const boost = selectedBoost;

        // Check if the boost can be applied
        if (userData.points < boost.cost && !boost.freeForNewUsers) {
            setErrorMessage('Insufficient balance to purchase this boost.');
            return;
        }

        if (boost.freeForNewUsers && user.isNew) {
            setSelectedBoost(boost);
            setErrorMessage(''); // No error if free for new users
        } else if (userData.points >= boost.cost) {
            setSelectedBoost(boost);
            setErrorMessage(''); // No error if they can afford it
    
            // Handle "Super Recharge"
            if (boost.name === 'Super Recharge') {
                axios.put(`${apiURL}user/update/${telegram_id}/energy`, {
                    energy: 250, // Assuming 500 is the max energy value
                    cost: boost.cost,
                })
                    .then(response => {
                        console.log('Energy updated successfully:', response.data);
                        fetchUserData(); // Update UI
                        handleDialogClose()
                    })
                    .catch(error => {
                        console.error('Error updating energy:', error);
                        setErrorMessage('Failed to update energy. Please try again.');
                    });
            }
    
            // Handle "Boost x9"
            if (boost.name === 'Boost x9') {
                axios.put(`${apiURL}user/update/${telegram_id}/perClick`, {
                    perClick: 9, // Set perClick to 9
                    cost: boost.cost,
                })
                    .then(response => {
                        console.log('perClick updated successfully:', response.data);
                        fetchUserData(); // Update UI
                        handleDialogClose()
                    })
                    .catch(error => {
                        console.error('Error updating perClick:', error);
                        setErrorMessage('Failed to update perClick. Please try again.');
                    });
            }
    
            // Handle "Boost x6"
            if (boost.name === 'Boost x6') {
                axios.put(`${apiURL}user/update/${telegram_id}/perClick`, {
                    perClick: 6, // Set perClick to 6
                    cost: boost.cost,
                })
                    .then(response => {
                        console.log('perClick updated successfully:', response.data);
                        fetchUserData(); // Update UI
                        handleDialogClose()
                    })
                    .catch(error => {
                        console.error('Error updating perClick:', error);
                        setErrorMessage('Failed to update perClick. Please try again.');
                    });
            }
        } else {
            setErrorMessage('Insufficient balance to purchase this boost.');
            setSelectedBoost(null); // Do not select the boost if funds are insufficient
        }
    };
    

    const handleDialogClose = () => {
        setSelectedBoost(null);
    };

    const renderBoostDetails = () => (
        <Slide direction="up" in={!!selectedBoost} unmountOnExit>
            <DetailsContainer
                sx={{
                    background: 'linear-gradient(135deg, #110830 0%, #1C2D50 100%)',
                    color: '#FFF',
                    padding: '20px',
                    borderRadius: '25px',
                    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)', // Soft shadow
                    position: 'relative',
                    height: 'auto', // Allow content to adjust based on its height
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between', // Distribute content evenly
                }}
            >
                {/* Close Button */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        cursor: 'pointer',
                        color: '#FFC107',
                        fontSize: '1.5rem',
                        zIndex: 1000,
                    }}
                    onClick={handleDialogClose}
                >
                    ✖
                </Box>
    
                {/* Icon with Glow */}
                <Box display="flex" justifyContent="center" mb={2}>
                    <Box
                        sx={{
                            backgroundColor: '#29293b',
                            borderRadius: '15px',
                            padding: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0 0 15px rgba(244, 196, 48, 0.8)', // Glow effect
                        }}
                    >
                        {selectedBoost?.icon}
                    </Box>
                </Box>
    
                {/* Title with Shadow */}
                <Typography
                    variant="h4"
                    align="center"
                    gutterBottom
                    sx={{
                        fontWeight: 800,
                        textShadow: '2px 2px 8px rgba(0,0,0,0.6)',
                        fontSize: '1.5rem', // Smaller font to fit better
                    }}
                >
                    {selectedBoost?.name}
                </Typography>
    
                {/* Fancy Divider */}
                <Box
                    sx={{
                        width: '60%',
                        height: '2px',
                        background: 'linear-gradient(to right, #FFC107, #6E3CBC)',
                        margin: '10px auto',
                        borderRadius: '5px',
                    }}
                />
    
                {/* Description */}
                <Typography
                    variant="body1"
                    align="center"
                    color="white"
                    mb={2}
                    sx={{ fontSize: '1rem' }} // Smaller font to fit better
                >
                    {selectedBoost?.details}
                </Typography>
    
                {/* Cost and Level */}
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mb={2}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{ color: '#FFC107', fontSize: '1.2rem' }}
                    >
                        <Typography
                            variant="h6"
                            mr={1}
                            sx={{ display: 'flex', alignItems: 'center' }}
                        >
                            🌕 {selectedBoost?.cost === 0 ? 'Free' : selectedBoost?.cost.toLocaleString()}
                        </Typography>
                        <Typography variant="body2" color="#AAA" ml={1}>
                            | {selectedBoost?.level}
                        </Typography>
                    </Box>
                </Box>
    
                {/* Get Button */}
                <Box mt={2}>
                    <StyledButton
                        fullWidth
                        sx={{
                            backgroundColor: selectedBoost?.cost === 0 ? '#6E3CBC' : '#FF5722',
                            color: '#FFF',
                            padding: '10px 0', // Reduced padding for better fit
                            borderRadius: '20px',
                            fontSize: '1.1rem', // Slightly smaller font for better fit
                            fontWeight: 700,
                            boxShadow: '0 5px 15px rgba(110, 60, 188, 0.6)',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                backgroundColor: selectedBoost?.cost === 0 ? '#844dbe' : '#FF7043',
                                transform: 'scale(1.05)', // Slight scale up on hover
                                boxShadow: '0 8px 25px rgba(132, 77, 190, 0.8)',
                            },
                        }}
                        onClick={applyBoost}
                    >
                        {selectedBoost?.cost === 0 ? 'Claim for Free!' : 'Get it!'}
                    </StyledButton>
                </Box>
            </DetailsContainer>
        </Slide>
    );
    
    
    
    return (
        <>
            <Modal
                open={open}
                onClose={() => {
                    handleClose();
                    handleDialogClose();  // Clean up selected boost on modal close
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                    style: { backdropFilter: 'blur(10px)' },
                }}
            >
                <ModalContainer>
                    {/* Header Section */}
                    <HeaderSection>
                        <Typography variant="h5" color="#FFF" mb={1}>
                            ZaksCoin Balance
                        </Typography>
                        <LargeNumber>{userData.points ? userData.points.toLocaleString() : '0'}</LargeNumber>
                    </HeaderSection>

                    {/* Error Message */}
                    {errorMessage && (
                        <Typography color="error" align="center" mb={2}>
                            {errorMessage}
                        </Typography>
                    )}

                    {/* Free Boosts Section */}
                    <SectionContainer>
                        <SectionTitle>Free Boosts</SectionTitle>
                        {boostData.slice(0, 2).map((boost, index) => (
                            <CardStyled key={index}>
                                <Box display="flex" alignItems="center">
                                    {boost.icon}
                                    <Typography sx={{ ml: 2 }}>
                                        {boost.name} - {boost.freeForNewUsers && user.isNew ? 'Free for You' : `${boost.details}`}
                                    </Typography>
                                </Box>
                            </CardStyled>
                        ))}
                    </SectionContainer>
                    {/* Upgrades Section */}
                    <SectionContainer>
                        <SectionTitle>Upgrades 🔒</SectionTitle>
                        {Array.isArray(boostData) ? (
                            boostData.slice(2).map((boost, index) => (
                                <CardStyled key={index} onClick={() => handleBoostClick(boost)}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        {boost.icon}
                                        <Typography sx={{ ml: 2 }}>
                                            {boost.name} - {boost.cost?.toLocaleString() || 'N/A'}
                                        </Typography>
                                    </Box>
                                </CardStyled>
                            ))
                        ) : (
                            <Typography>No upgrades available</Typography>
                        )}
                    </SectionContainer>


                    {/* Close Button */}
                    <Box mt={4} display="flex" justifyContent="center">
                        <StyledButton onClick={handleClose}>Close</StyledButton>
                    </Box>
                </ModalContainer>
            </Modal>

            {/* Boost Details */}
            {selectedBoost && renderBoostDetails()}
        </>
    );
};

export default BoostModal;
