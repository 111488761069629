import React, { useState } from 'react';
import {
  Modal, Backdrop, Fade, Button, Typography, Box, TextField, IconButton, Card, Grid,
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import SearchIcon from '@mui/icons-material/Search';
import TelegramIcon from '@mui/icons-material/Telegram';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { message } from 'antd';
import moment from 'moment';

// Keyframe Animations
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const staggeredFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const bounceIn = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

// Styled Components
const ModalContainer = styled('div')(({ open }) => ({
  background: 'linear-gradient(135deg, rgb(17, 8, 48) 0%, #4A00E0 100%)',
  borderRadius: '20px',
  maxWidth: '700px',
  padding: '40px',
  margin: 'auto',
  // marginBottom: '4vh',
  height: '85vh',
  overflowY: 'auto',
  animation: `${fadeInUp} 0.5s ease-out both`,
  boxShadow: '0px 12px 30px rgba(0, 0, 0, 0.5)',
  '@media (max-width: 600px)': {
    padding: '20px',
    height: '90vh',
    maxWidth: '95vw',
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '20px',
}));

const Badge = styled(Box)(({ rank }) => ({
  background: rank === 1 ? 'linear-gradient(45deg, #FFD700, #FF6F61)'
    : rank === 2 ? 'linear-gradient(45deg, #C0C0C0, #DE6262)'
    : 'linear-gradient(45deg, #CD7F32, #DE6262)',
  color: '#fff',
  padding: '6px 12px',
  borderRadius: '8px',
  fontWeight: 'bold',
  fontSize: '0.9rem',
  // animation: `${pulse} 1.5s infinite`,
  boxShadow: rank <= 3 ? '0 0 10px rgba(255, 255, 255, 0.7)' : 'none',
  '@media (max-width: 600px)': {
    padding: '5px 10px',
    fontSize: '0.8rem',
  },
}));

const Avatar = styled('div')(({ theme }) => ({
  width: '70px',
  height: '70px',
  borderRadius: '50%',
  background: 'linear-gradient(135deg, #4A00E0, #8E2DE2)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 700,
  color: '#fff',
  fontSize: '2rem',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
  '@media (max-width: 600px)': {
    width: '50px',
    height: '50px',
    fontSize: '1.5rem',
  },
}));

const ReferralCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '20px',
  color: '#fff',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
  animation: `${staggeredFadeIn} 0.6s ease-out both`,
  display: 'flex',
  alignItems: 'center',
  padding: '15px 25px',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.4)',
  },
  '@media (max-width: 600px)': {
    padding: '10px 15px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#4A00E0',
  color: '#fff',
  padding: '10px 25px',
  borderRadius: '30px',
  fontWeight: 700,
  textTransform: 'none',
  fontSize: '1rem',
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    backgroundColor: '#6A00F4',
    boxShadow: '0px 4px 15px rgba(74, 0, 224, 0.4)',
  },
  '@media (max-width: 600px)': {
    padding: '8px 20px',
    fontSize: '0.9rem',
  },
}));

const CopyShareButtonGroup = styled(Box)(({ theme }) => ({
  marginTop: '15px',
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    gap: '8px',
  },
}));

const ReferralLinkBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '10px',
  padding: '15px',
  marginBottom: '20px',
  color: '#fff',
  textAlign: 'center',
  wordWrap: 'break-word',
  fontSize: '0.9rem',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
  '@media (max-width: 600px)': {
    fontSize: '0.8rem',
    padding: '10px',
  },
}));

const ReferralCount = styled(Typography)(({ theme }) => ({
  color: '#FFC107',
  fontWeight: 700,
  fontSize: '2rem',
  textAlign: 'center',
  animation: `${bounceIn} 1s ease both`,
  '@media (max-width: 600px)': {
    fontSize: '1.5rem',
  },
}));

const formatNumber = (num) => {
  if (num >= 1000000) return (num / 1000000).toFixed(1) + 'M';
  if (num >= 1000) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return num;
};


// Main Component
const FriendsModal = ({ open, handleClose, userData = {}, referralCount = 0, referralList = [] }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => setSearchQuery(event.target.value.toLowerCase());

  const filteredReferrals = referralList
    .map(item => item.Referred)
    .filter(referral => `${referral.first_name} ${referral.last_name}`.toLowerCase().includes(searchQuery));

  const handleReferral = () => {
    const textToCopy = `https://t.me/zakscoin_bot?start=${userData.telegram_id}`;
    navigator.clipboard.writeText(textToCopy)
      .then(() => message.success('Copied to clipboard!'))
      .catch(() => message.error('Failed to copy text to clipboard.'));
  };


  const handleShare = () => {
    const shareData = {
      title: 'Referral Points',
      text: 'Check out how many points my referrals have earned!',
      url: `https://t.me/zakscoin_bot?start=${userData.telegram_id}`,
    };

    if (navigator.share) {
      navigator.share(shareData)
        .then(() => message.success('Referral link shared successfully!'))
        .catch(() => message.error('Failed to share the referral link.'));
    } else if (window.Telegram.WebApp) {
      window.Telegram.WebApp.openUrl(shareData.url);
    } else {
      message.info('Sharing is not supported on this browser.');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backdropFilter: 'blur(10px)' },
      }}
    >
      <Fade in={open}>
        <ModalContainer>
          <Header>
            <IconButton onClick={handleClose} sx={{ color: '#fff' }}>
              <CloseIcon />
            </IconButton>
          </Header>

          {/* Total Referrals Section */}
          <ReferralCount>
            {`Total Referrals: ${referralCount}`}
          </ReferralCount>

          <ReferralLinkBox>
            {`https://t.me/zakscoin_bot?start=${userData?.telegram_id}`}
          </ReferralLinkBox>

          <CopyShareButtonGroup>
            <Button startIcon={<ContentCopyIcon />} onClick={handleReferral} sx={{ backgroundColor: '#4A00E0', color: '#fff' }}>
              Copy Link
            </Button>
            <Button startIcon={<ShareIcon />} onClick={handleShare} sx={{ backgroundColor: '#FFC107', color: '#4A00E0' }}>
              Share
            </Button>
          </CopyShareButtonGroup>

          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ color: '#fff', mb: 2 }}>Referral List</Typography>
            <TextField
              placeholder="Search..."
              variant="outlined"
              fullWidth
              onChange={handleSearchChange}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '10px',
                mb: 3,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              }}
              InputProps={{
                startAdornment: (
                  <SearchIcon position="start" sx={{ color: '#4A00E0', mr: 1 }} />
                ),
              }}
            />
            <Grid container spacing={2}>
              {filteredReferrals.length > 0 ? (
                filteredReferrals.map((referral, index) => (
                  <Grid item xs={12} key={index}>
                    <ReferralCard>
                      <Avatar>{referral.first_name.charAt(0).toUpperCase()}</Avatar>
                      <Box sx={{ ml: 2 }}>
                        <Typography variant="body1" sx={{ fontWeight: 700 }}>
                          {`${referral.first_name} ${referral.last_name}`}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#FFC107' }}>
                          Referred on {moment(referral.created_at).format('MMMM Do YYYY')}
                          <Badge rank={index + 1}>
                            {formatNumber(referral.points)} 🟡
                          </Badge>
                        </Typography>
                      </Box>

                    </ReferralCard>
                  </Grid>
                ))
              ) : (
                <Typography variant="body1" sx={{ color: '#fff', textAlign: 'center', width: '100%', mt: 3 }}>
                  No referrals found.
                </Typography>
              )}
            </Grid>
          </Box>
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <CustomButton startIcon={<TelegramIcon />}>
              Invite More Friends
            </CustomButton>
          </Box>
        </ModalContainer>
      </Fade>
    </Modal>
  );
};

export default FriendsModal;
